body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  /*text-align: center;*/
}
body {
  font-family: "Overpass", Arial, sans-serif;
  background: #fff;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 300;
  color: gray;
}
.ftco-navbar-light {
  background-color: #000000 !important;
  position: absolute!important;
  left: 0;
  right: 0;
  z-index: 3;
  top: 0px;
  height: 3%;
}
.bg-warning {
  background: #f86f2d!important;
}

.navbar-nav > .nav-item > a {
  color: #f86f2d;
}
.block-18 {
  display: block;
  width: 100%;
  padding: 30px;
  color: rgba(0, 0, 0, 0.7);
}
.block-18.color-1 {
  background: #f8f52d;
}
.faq.questions {
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
}
.block-18.color-2 {
  background: #3dedfa;
}
.block-18.color-3 {
  background: #faaa3a;
}
.ftco-section {
  padding: 7em 0;
  position: relative;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
span.donation-time {
  font-style: italic;
  color: #cfcfcf;
}
.slider-text {
  color: #fff;
  height: 700px;
}

.ftco-footer {
  font-size: 16px;
  padding: 7em 0;
  background: #252525;
}
.ftco-section {
  padding: 7em 0;
  position: relative;
}
.ftco-footer .ftco-footer-widget h2 {
  color: #fff;
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.ftco-footer .ftco-footer-widget h2:after {
  position: absolute;
  bottom: -15px;
  left: 0;
  content: '';
  width: 70px;
  height: 1px;
  background: #fff;
}
.ftco-footer p {
  color: rgba(255, 255, 255, 0.7);
}
.ftco-footer a {
  color: rgba(255, 255, 255, 0.7);
}
.block-23 ul {
  padding: 0;
}
footer ul {
  list-style-type: none;
}
.schoolWrap .list-group-item.active {
  z-index: 2;
  color: white!important;
  background-color: #f86f2d!important;
  border-color: #f86f2d;
  border-radius: 4px!important;
  font-size: 12px;
}

.detailWrap th, .detailWrap td{
  font-size: 12px;
  vertical-align: middle;
}
.detailWrap th:first-child, .detailWrap td:first-child{
  min-width: 36px;
  text-align: center;
}
.detailWrap th:nth-child(2), .detailWrap td:nth-child(2){
  min-width: 130px;
}
.detailWrap .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(248,111,45,.1);
}
.detailWrap td a, .featured a{
  color: #f86f2d!important;
}
.subMenuWrap-Right a{
  color: #f5f1f1!important;
  font-size: 20px;
}
.imageCredit {
  font-size: 8px;
  text-align: end;
}
